<div class="subjectDataCOntainer" (click)="showData()">
  <div class="resultsData">
    <span>{{ subjectGroup.subjects?.id }} {{ subjectGroup.subjects?.NameSubject }}</span>
    <span>{{ subjectGroup.id }} {{ subjectGroup.NameSubjectGroup }}</span>
  </div>
  <div class="resultsActions">
    @if (showProfesorSelect) {
      <div>
        <div class="pill-list">
          @for (item of multiselectItemsChecked(); track $index) {
            <ui-badge>{{item.name}} <em class="icon-i-close badge-close" (click)="removeBadge(item.id)"></em></ui-badge>
          }
        </div>
        <ui-dropdown [closeOnSelect]="false" (onValueChange)="onSelectMultiselect($event)">
          <ui-dropdown-trigger>
            <ui-input [(ngModel)]="multiselectInputValue" placeholder="Select an item" />
          </ui-dropdown-trigger>
          <ui-dropdown-content>
            @for (item of multiselectItems(); track $index) {
              @if(item.disabled !== true){
                <ui-dropdown-item [value]="item.id">
                  <span class="m-select-item">
                    {{item.name}} <em class="icon-i-add m-select-add-icon"></em>
                  </span>
                </ui-dropdown-item>
              }
            }

            @if(multiselectItems().length === 0 || multiselectItems().length === multiselectItemsChecked().length){
              <span>No results found.</span>
            }
          </ui-dropdown-content>
        </ui-dropdown>
      </div>
    }
    <button class="invisibleBtn localeBtn" (click)="showProfesorSelect = !showProfesorSelect">{{ showProfesorSelect ? 'Cancelar' : 'Asignar Docentes'}}</button>
    @if (showProfesorSelect) {
      <button class="invisibleBtn localeBtn" (click)="saveProfesorList()">Guardar</button>
    }
  </div>
</div>
