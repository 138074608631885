import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { PerformanceOffer } from '@models/dbv2/performance-offer';
import { Task } from '@models/dbv2/task';
import { UserRecordV2 } from '@models/dbv2/user-record-v2';
import { ApiService } from '@services/api-service.service';
import * as UiSelect from 'src/app/components/ui/ui-select';
import { TasksPipe } from './pipes/tasks';
import { concat, forkJoin, map, switchMap, toArray } from 'rxjs';
import { SubjectV2GroupOffer } from '@models/dbv2/subject-v2-group-offer';
import { MidTermsV2 } from '@models/dbv2/mid-terms-v2';
import Swal from 'sweetalert2';

interface StudentData extends UserRecordV2 {
  tasks: Task[];
  canEdit?:boolean;
}

@Component({
  selector: 'app-performance-management',
  standalone: true,
  imports: [
    CommonModule,
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
    TasksPipe,
  ],
  templateUrl: './performance-management.component.html',
  styleUrl: './performance-management.component.css',
})
export class PerformanceManagementComponent implements OnInit {
  constructor(private readonly _httpService: ApiService) {}
  public performaces = signal<PerformanceOffer[]>([]);
  public studentsData = signal<StudentData[]>([]);
  public subjectGroups = signal<SubjectV2GroupOffer[]>([]);
  public minTerms = signal<MidTermsV2[]>([]);

  public updateAll = signal<boolean>(false);
  private _selectedMinTerm: number = 0;

  ngOnInit(): void {
    this._httpService
      .get<SubjectV2GroupOffer>({
        path: 'SubjectV2GroupOffers',
        filter: {
          include: ['SubjectV2Offer'],
        },
      })
      .subscribe({
        next: (resp) => {
          this.subjectGroups.set(resp);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  public onSelectGroup(value: string) {
    this._httpService
      .get<MidTermsV2>({
        path: 'MidTermsV2',
        filter: {
          where: { subjectV2GroupOfferID: Number(value) },
        },
      })
      .subscribe({
        next: (resp) => {
          this.minTerms.set(resp);
          console.log(resp);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  public onSelectMinTerm(value: string) {
    this._selectedMinTerm = Number(value);
    this.getPerformacesQuery(Number(value)).subscribe({
      next: (performances) => {
        console.log(performances);
        this.refreshPeformances(performances);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public onEditAll(){
    this.updateAll.set(!this.updateAll());
    this.studentsData.update( prev => prev.map( e => ({...e,  canEdit: this.updateAll()})))
  }



  //TODO: REFACTORIZAR ESTO Y L ODE ABAJO
  public saveTask(userRecord:StudentData){
    Swal.fire({
      title: 'Guardando datos...',
      html: 'Por favor, espera mientras se actualizan los datos.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    const allTasks = userRecord.tasks;
    const records$ = allTasks.map((t) => {
      const path = t.id ? `Tasks/${t.id}` : 'Tasks';
      return this._httpService.patch<Task>({
        path,
        data:t,
      });
    });

    concat(...records$)
      .pipe(
        toArray(),
        switchMap(() => this._httpService.post({
          path: 'Tasks/calculateFinalGrade',
          data:{
            studentID: userRecord.UserSubjectV2?.studentID,
            userRecordID: userRecord.id
          }
        })),
        switchMap(() => this.getPerformacesQuery(this._selectedMinTerm))
      )
      .subscribe({
        next: (resp) => {
          this.refreshPeformances(resp);
          Swal.fire({
            title: '¡Datos actualizados!',
            text: 'Los datos se han guardado exitosamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        },
        error: (err) => {
          console.log(err);
          Swal.fire({
            title: 'Error',
            text: 'Parece que algo salió mal. Si el problema persiste, por favor contacta a soporte técnico.',
            icon: 'error',
          });
        },
      });
  }

  public saveAllTasks() {
    Swal.fire({
      title: 'Guardando datos...',
      html: 'Por favor, espera mientras se actualizan los datos.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    const saveTasksObservables$ = this.studentsData().map((userRecord) => {
      const tasks$ = userRecord.tasks.map((t) => {
        const path = t.id ? `Tasks/${t.id}` : 'Tasks';
        return this._httpService.patch<Task>({
          path,
          data: t,
        });
      });
  
      return concat(...tasks$).pipe(
        toArray(),
        switchMap(() =>
          this._httpService.post({
            path: 'Tasks/calculateFinalGrade',
            data: {
              studentID: userRecord.UserSubjectV2?.studentID,
              userRecordID: userRecord.id,
            },
          })
        )
      );
    });

    concat(...saveTasksObservables$)
      .pipe(
        toArray(),
        switchMap(() => this.getPerformacesQuery(this._selectedMinTerm))
      )
      .subscribe({
        next: (resp) => {
          this.refreshPeformances(resp);
          Swal.fire({
            title: '¡Datos actualizados!',
            text: 'Los datos se han guardado exitosamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        },
        error: (err) => {
          console.log(err);
          Swal.fire({
            title: 'Error',
            text: 'Parece que algo salió mal. Si el problema persiste, por favor contacta a soporte técnico.',
            icon: 'error',
          });
        },
      });
  }

  private refreshPeformances(data: Record<string, any>) {
    const { performaces, records, tasks } = data;

    this.performaces.set(performaces);

    const studentsData: StudentData[] = [];
    for (let x = 0; x < records.length; x++) {
      const record: StudentData = records[x];

      const studentTasks = tasks.filter(
        (t: Task) => t.studentID === record.UserSubjectV2?.studentID
      );
      record.tasks = studentTasks;
      studentsData.push(record);
    }

    this.studentsData.set(studentsData);
  }

  public onChangeRecord(
    item: StudentData,
    performanceId: number,
    taskNumber: number,
    event: Event
  ) {
    const value = (event.target as HTMLInputElement).value;

    const tasks: Task[] = item.tasks;

    const existingTask = tasks.find(
      (t) =>
        t.academicPerformanceOfferID === performanceId &&
        t.taskNumber === taskNumber
    );

    if (existingTask) {
      existingTask.taskRecord = Number(value);
    } else {
      const newRecord = {
        academicPerformanceOfferID: performanceId,
        midTermV2ID: this._selectedMinTerm,
        taskNumber,
        taskRecord: Number(value),
        studentID: item.UserSubjectV2!.studentID!,
      };

      tasks.push(newRecord);
    }

    this.studentsData.update((prev) => {
      const student = prev.find(
        (s) => s.UserSubjectV2?.studentID === item.UserSubjectV2?.studentID
      )!;
      student.tasks = tasks;
      return prev;
    });
  }

  public getPerformacesQuery(midTermId: number) {
    return this._httpService
      .get<PerformanceOffer>({
        path: 'AcademicPerformancesOffer',
        filter: {
          where: {
            and: [{ tasksNumber: { gt: 0 } }, { midTermV2ID: midTermId }],
          }
        }
      })
      .pipe(
        switchMap((performaces) => {
          const minTermIds = performaces.map((r) => r.midTermV2ID);
          const performanceIds = performaces.map((r) => r.id);
          return forkJoin({
            tasks: this._httpService.get<Task>({
              path: 'Tasks',
              filter: {
                where: {
                  and: [
                    { midTermV2ID: { inq: minTermIds } },
                    { academicPerformanceOfferID: { inq: performanceIds } },
                  ],
                },
              },
            }),
            records: this._httpService.get<Task>({
              path: 'UserRecordsV2',
              filter: {
                include: [{ UserSubjectV2: 'Student' }],
                where: { and: [{ midTermV2ID: { inq: minTermIds } }] },
              },
            }),
          }).pipe(map((data) => ({ performaces, ...data })));
        })
      );
  }
}