import { Component } from '@angular/core';
import { UiSelectComponent } from "../ui/ui-select/ui-select.component";
import { UiSelectContentComponent } from "../ui/ui-select/ui-select-content/ui-select-content.component";
import { UiSelectItemComponent } from "../ui/ui-select/ui-select-item/ui-select-item.component";
import { ApiService } from '../../services/api-service.service';
import { SweetalertService } from '../../services/sweetalert.service';
import { environment } from '../../../environments/environment';
import { IDropdownItem } from '../ui/models/dropdown-item';
import { School } from '../../models/db/school';
import { SubjectListComponent } from "../../shared/assign-professor/subject-list/subject-list.component";
import { forkJoin, take } from 'rxjs';
import { CourseOffer } from '../../models/db/courseOffer';
import { Subject } from '../../models/db/Subject';
import { SubjectGroup } from '../../models/db/SubjectGroup';
import { FormsModule } from '@angular/forms';
import { UserJobsContract } from '../../models/db/UserJobsContract';
import { CourseGroup } from '../../models/db/CourseGroup';

@Component({
  selector: 'app-assign-professor',
  standalone: true,
  imports: [
    /** Jhan components */
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    /** Angular components */
    FormsModule,
    /** Own components */
    SubjectListComponent
  ],
  templateUrl: './assign-professor.component.html',
  styleUrl: './assign-professor.component.css'
})
export class AssignProfessorComponent {

  protected textsToShow = {
    schoolFilterTittle: 'Filtro por __',
  };
  protected MANAGE_SELECTS_DATA: ManageSelectDataInt = {
    COURSES_OFERT: {
      ALL_LIST: [],
      selected: 0
    },
    SUBJECTS: {
      ALL_LIST: [],
      selected: 0
    },
    SUBJECTS_GROUPS: {
      ALL_LIST: [],
      selected: 0
    },
    COURSE_GROUPS: [],
    USER_JOB_CONTRACT: [],
  };

  protected filtersData: FiltersData = {
    selectOptions: {
      schools: this._apiService.schools(),
      // categories: [],
      // allTypeCourses: [],
      // typeCoursesToShow: [],
    },
    dataSelected: {
      school: -1,
      // category: -1,
      // typeCourse: -1,
      // inputText: '',
    }
  };

  constructor (
    readonly _apiService: ApiService,
    readonly _sweetalertService: SweetalertService,
  ) {
    this.textsToShow = {
      schoolFilterTittle: `Filtro por ${environment.APP_DATA.SCHOOL_TEXT.toLowerCase()}`
    };
  };

  private getAllCourseOfferDataFromSchoolID(schoolID: number): void {
    console.log('getAllCourseOfferDataFromSchoolID: ', schoolID);
    const dataToGetAllCourseOffers = {
      path: "/CoursesOfers",
      filter: {
        order: "NameCourseOfer ASC",
        where: {
          and: [
            { SchoolID: schoolID },
            { IsActive: true }
          ]
        }
      }
    };
    const dataToGetAllUserContracts = {
      path: `/Schools/${schoolID}/UserJobsContracts`,
      filter: {
        include: [ "TypeContracts", "Userapps" ]
      }
    };
    
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_COURSE_OFERT_LIST: this._apiService.get<CourseOffer>( dataToGetAllCourseOffers ).pipe( take(1) ),
      FJ_ALL_USER_CONTRACT_LIST: this._apiService.get<UserJobsContract>( dataToGetAllUserContracts ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllCourseOfferDataFromSchoolID response:", forkjoinResponse);
        let { FJ_ALL_COURSE_OFERT_LIST, FJ_ALL_USER_CONTRACT_LIST } = forkjoinResponse;
        this.MANAGE_SELECTS_DATA.COURSES_OFERT.ALL_LIST = FJ_ALL_COURSE_OFERT_LIST;
        this.MANAGE_SELECTS_DATA.USER_JOB_CONTRACT = FJ_ALL_USER_CONTRACT_LIST;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllCourseOfferDataFromSchoolID error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllCourseOfferDataFromSchoolID complete:");
      }
    });
  }

  private getAllSubjectsDataFromCourseOfferID(courseOfferID: number): void {
    console.log('getAllSubjectsDataFromCourseOfferID: ', courseOfferID);
    let courseIDFromCourseOfferSelected = this.MANAGE_SELECTS_DATA.COURSES_OFERT.ALL_LIST.find( x => x.id == courseOfferID)!;

    // Revisar schedule/full en el filtro para celic y cedoc
    const dataToGetAllSubjects = {
      path: "/subjects",
      filter: {
        order: "NameSubject ASC",
        where: {
          and: [
            { CourseID: courseIDFromCourseOfferSelected.CourseID },
            // { CourseID: courseOfferID },
            // { AcademicAreaID: this.formFilterSchedule.AcademicAreaID },
            { SemesterNumber: this.filterSubjectsByGrade() },
          ]
        },
        include: [
          { relation: "Courses",
            scope: {
              fields: ["IsSemestralize"]
            }
          },
          { relation: "subjectgroup" }
        ]
      }
    };
    let filterCourseGroups = {
      path: "/CourseGroups",
      filter: {
        where: {
          order: 'name ASC',
          CourseOferID: courseOfferID,
        },
      }
    };
    const today = new Date();
    const nextWeek = new Date(today.setDate(today.getDate() + 5));
    let filterContracts = {
      path: "/UserJobsContracts",
      filter: {
        where: {
          and: [
            { SchoolID: courseIDFromCourseOfferSelected.SchoolID },
            { StartContract: { lt: nextWeek.toISOString() } },
            { EndContract: { gt: nextWeek.toISOString() } },
          ],
        },
        include: ['Userapps', 'TypeContracts'],
      }
    }
    
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_SUBJECT_LIST: this._apiService.get<Subject>( dataToGetAllSubjects ).pipe( take(1) ),
      FJ_ALL_COURSE_GROUPS_LIST: this._apiService.get<CourseGroup>( filterCourseGroups ).pipe( take(1) ),
      FJ_ALL_USER_JOB_CONTRACT_LIST: this._apiService.get<UserJobsContract>( filterContracts ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllSubjectsDataFromCourseOfferID response:", forkjoinResponse);
        let { FJ_ALL_SUBJECT_LIST, FJ_ALL_COURSE_GROUPS_LIST, FJ_ALL_USER_JOB_CONTRACT_LIST } = forkjoinResponse;
        this.MANAGE_SELECTS_DATA.SUBJECTS.ALL_LIST = FJ_ALL_SUBJECT_LIST;
        this.MANAGE_SELECTS_DATA.COURSE_GROUPS = FJ_ALL_COURSE_GROUPS_LIST;
        // this.MANAGE_SELECTS_DATA.USER_JOB_CONTRACT = FJ_ALL_USER_JOB_CONTRACT_LIST;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllSubjectsDataFromCourseOfferID error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllSubjectsDataFromCourseOfferID complete:");
      }
    });
  }

  private getAllSubjectGroupsDataFromSubjectID(subjectID: number): void {
    console.log('getAllSubjectGroupsDataFromSubjectID: ', subjectID);
    const dataToGetAllSubjectGroups = {
      path: "/SubjectGroups",
      filter: {
        where: {
          and: [
            { SubjectID: subjectID },
            // { CourseGroupID: subjectID }
            // { CourseOferID: this.MANAGE_SELECTS_DATA.COURSES_OFERT.selected }
            { CourseGroupID: { inq: [ this.MANAGE_SELECTS_DATA.COURSE_GROUPS.filter((x) => x.id! > 0).map((x) => x.id)] } }
          ]
        },
        include: [ "scheduleSubjects", "userapp", "subjects", "coursesgroup" ]
      }
    };
    
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_SUBJECT_GROUP_LIST: this._apiService.get<SubjectGroup>( dataToGetAllSubjectGroups ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllSubjectGroupsDataFromSubjectID response:", forkjoinResponse);
        let { FJ_ALL_SUBJECT_GROUP_LIST } = forkjoinResponse;
        this.MANAGE_SELECTS_DATA.SUBJECTS_GROUPS.ALL_LIST = FJ_ALL_SUBJECT_GROUP_LIST;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllSubjectGroupsDataFromSubjectID error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllSubjectGroupsDataFromSubjectID complete:");
      }
    });
  }

  protected onSchoolSelectChange(optionSelected: IDropdownItem): void {
    console.log('optionSelected', optionSelected);
    this.getAllCourseOfferDataFromSchoolID(+optionSelected.value);
  }

  protected onCourseOfferSelectChange(optionSelected: IDropdownItem): void {
    console.log('optionSelected', optionSelected);
    this.getAllSubjectsDataFromCourseOfferID(+optionSelected.value);
  }

  protected onSubjectSelectChange(optionSelected: IDropdownItem): void {
    console.log('optionSelected', optionSelected);
    this.getAllSubjectGroupsDataFromSubjectID(+optionSelected.value);
  }

  private filterSubjectsByGrade(): number {
    const selectedCourseOffer = this.MANAGE_SELECTS_DATA.COURSES_OFERT.ALL_LIST.find(
      (e) => e.id == this.MANAGE_SELECTS_DATA.COURSES_OFERT.selected
    );
    if (selectedCourseOffer) {
      const text = selectedCourseOffer.NameCourseOfer!.toLowerCase();

      const courseRegex = /(prejardín|jardín|transición)/i;
      const gradeRegex = /grado\s*(\d+)/i;

      const courseResult = courseRegex.exec(text);

      let courseIndex = 0;
      if (courseResult && courseResult.length > 1) {
        const courseName = courseResult[1].toLowerCase();
        console.log(courseName);
        switch (courseName) {
          case 'prejardín':
            courseIndex = 1;
            break;
          case 'jardín':
            courseIndex = 2;
            break;
          case 'transición':
            courseIndex = 3;
            break;
          default:
            break;
        }

        return courseIndex;
      }

      const gradeResult = gradeRegex.exec(text);
      if (gradeResult && gradeResult.length > 1) courseIndex = parseInt(gradeResult[1]);

      return courseIndex;
    } else return 0;
  }

}


interface FiltersData {
  selectOptions: {
    schools: School[],
    // categories: Category[],
    // allTypeCourses: TypeCourse[],
    // typeCoursesToShow: TypeCourse[],
  };
  dataSelected: {
    school: number,
    // category: number,
    // typeCourse: number,
    // inputText: string,
  };
}

interface ManageSelectDataInt {
  COURSES_OFERT: {
    ALL_LIST: CourseOffer[],
    selected: number;
  },
  SUBJECTS: {
    ALL_LIST: Subject[],
    selected: number;
  },
  SUBJECTS_GROUPS: {
    ALL_LIST: SubjectGroup[],
    selected: number;
  },
  COURSE_GROUPS: CourseGroup[],
  USER_JOB_CONTRACT: UserJobsContract[],
}



