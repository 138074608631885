<div class="containerAssignProfesor">
  <div class="filterSection">
    <div class="filterSection_item filterSection_item--width">
      <ui-select [variant]="'bordered'" [label]="textsToShow.schoolFilterTittle" defaultValue="0" (onValueChange)="onSchoolSelectChange($event)">
        <ui-select-content>
          <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
          @for (schoolIterated of filtersData.selectOptions.schools; track schoolIterated.id) {
            <ui-select-item [value]="schoolIterated.id!">{{ schoolIterated.NameTSchool }}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    <div class="filterSection_item--width"></div>
  </div>
  <div class="contentSection">
    <div class="contentSection_localFilters">
      <div class="localFilters_item">
        <ui-select [label]="'Curso'" defaultValue="0" [(ngModel)]="MANAGE_SELECTS_DATA.COURSES_OFERT.selected" (onValueChange)="onCourseOfferSelectChange($event)">
          <ui-select-content>
            <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
            @for (courseOffer of MANAGE_SELECTS_DATA.COURSES_OFERT.ALL_LIST; track courseOffer.id) {
              <ui-select-item [value]="courseOffer.id!">{{ courseOffer.NameCourseOfer }}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>
      <div class="localFilters_item">
        <ui-select [label]="'Materia'" defaultValue="0" [(ngModel)]="MANAGE_SELECTS_DATA.SUBJECTS.selected" (onValueChange)="onSubjectSelectChange($event)">
          <ui-select-content>
            <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
            @for (subject of MANAGE_SELECTS_DATA.SUBJECTS.ALL_LIST; track subject.id) {
              <ui-select-item [value]="subject.id!">{{ subject.NameSubject }}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>
      <div class="localFilters_item">
        <ui-select [label]="'Grupo'" defaultValue="0" (onValueChange)="onSchoolSelectChange($event)">
          <ui-select-content>
            <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
            @for (subjectGroup of MANAGE_SELECTS_DATA.SUBJECTS_GROUPS.ALL_LIST; track subjectGroup.id) {
              <ui-select-item [value]="subjectGroup.id!">{{ subjectGroup.NameSubjectGroup }}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>
    </div>
    <div class="contentSection_results">
      @for (item of MANAGE_SELECTS_DATA.SUBJECTS_GROUPS.ALL_LIST; track $index) {
        <AP-subject-list
          [subjectGroup]="item"
          [profesorList]="MANAGE_SELECTS_DATA.USER_JOB_CONTRACT"
        ></AP-subject-list>
      } @empty {
        @if (MANAGE_SELECTS_DATA.SUBJECTS.selected != 0) {
          <div><span>No hay grupos creados para la matería seleccionada</span></div>
        }
      }
    </div>
  </div>
</div>
