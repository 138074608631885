import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class XLSXService {

  public exportFile(data:unknown[], fileName:string, sheetName:string = 'Sheet1'){
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {
      Sheets: { [sheetName]: worksheet },
      SheetNames: [sheetName],
    };

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  public loadFile(file:File): Promise<any[]>{
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        try {
          const arrayBuffer = event.target?.result as ArrayBuffer;
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });

          const allData: any[] = [];
          workbook.SheetNames.forEach((sheetName) => {
            const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            allData.push({ sheetName, rows });
          });

          resolve(allData);
        } catch (error) {
          reject(`Error procesando el archivo Excel: ${error}`);
        }
      }
      reader.onerror = (error) => {
        reject(`Error al leer el archivo: ${error}`);
      };

      reader.readAsArrayBuffer(file)
    })
  }
}
