<div class="records">
  <div class="records__header">
    <div class="header__info">
      <h1 class="header__info-title">Gestión de Notas</h1>
      <h2 class="header__info-subtitle">
        En esta sección usted podrá gestionar las notas de los estudiantes en la
        materia asignada
      </h2>
    </div>

    <div class="header__filters">
      <ui-select label="Oferta" (onValueChange)="onSelectOffer($event.value)">
        <ui-select-content>
          @for (item of courseOffers(); track $index) {
          <ui-select-item [value]="item.id!">{{
            item.NameCourseOfer
          }}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>

      <ui-select
        label="Materia"
        (onValueChange)="onSelectSubject($event.value)"
      >
        <ui-select-content>
          @for (item of subjectGroups(); track $index) {
          <ui-select-item [value]="item.id!">
            {{ item.SubjectV2Offer?.nameSubject }} - ({{
              item.nameSubjectV2GroupOffer
            }})
          </ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
  </div>

  <div class="records__content">
    @if (selectedSubjectGroup()) {
    <div class="header__tabs">
      <div class="tabs__container">
        @for (item of selectedSubjectGroup()!.MidTermsV2; track $index) {
        <label
          class="tab-item"
          [ngClass]="{
            'tab-item--checked': selectedMinTerm()?.id === item.id
          }"
          [for]="item.id"
        >
          {{ item.nameMid }} {{ item.midTermNum }} - {{ item.percentage }}%
          <input
            name="tab"
            [id]="item.id"
            type="radio"
            (change)="this.selectedMinTerm.set(item)"
            hidden
          />
        </label>
        }
      </div>
      <button
        type="button"
        class="button button--underline"
        (click)="showEditInputs.set(!this.showEditInputs())"
      >
        @if(showEditInputs()){Cancelar <em class="icon-i-close"></em>} @else
        {Editar Notas <em class="icon-i-pen"></em>}
      </button>
    </div>

    <div class="dates">
      <div class="dates__info">
        <div class="dates__info-box">
          <div>Inicio del periodo</div>
          <div>{{ selectedMinTerm()?.midTermStart | date }}</div>
        </div>
        <div class="dates__info-box">
          <div>Finalización del periodo</div>
          <div>{{ selectedMinTerm()?.midTermEnd | date }}</div>
        </div>
      </div>

      <div class="date-types">
        <div
          class="date__box"
          [ngClass]="{ 'date__box--red': !canEditRecord() }"
        >
          <h2 class="date__box-title">Cargue de Notas</h2>
          <div class="date__box-dates">
            <div class="dates__info-box">
              <div>Inicio</div>
              <div>{{ selectedMinTerm()?.midTermRecordStart | date }}</div>
            </div>
            <div class="dates__info-box">
              <div>Fin</div>
              <div>{{ selectedMinTerm()?.midTermRecordEnd | date }}</div>
            </div>
          </div>
        </div>
        <div
          class="date__box"
          [ngClass]="{ 'date__box--red': !canEditRemedialGrade() }"
        >
          <h2 class="date__box-title">Recuperacón</h2>
          <div class="date__box-dates">
            <div class="dates__info-box">
              <div>Inicio</div>
              <div>{{ selectedMinTerm()?.remedialGradeStart | date }}</div>
            </div>
            <div class="dates__info-box">
              <div>Fin</div>
              <div>{{ selectedMinTerm()?.remedialGradeEnd | date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

    @if(selectedMinTerm()){
      <div class="actions">
        <div class="actions__general">
          <button type="button" class="button button--bordered"
           (click)="exportTemplate()">
            Exportar plantilla
          </button>
          <button type="button" class="button button--bordered" (click)="massiveNotes.click()">
            Cargar notas Masivas
            <input type="file" hidden accept=".xls,.xlsx" #massiveNotes (change)="massiveUpload($event)">
          </button>
          <button type="button" class="button button--bordered" (click)="exportFile()">
            Imprimir notas
          </button>
        </div>
        @if(showEditInputs()){
          <button
            type="button"
            class="button button--solid"
            (click)="saveRecords()"
          >
            Guardar notas
          </button>
        }
      </div>
    }

    <table class="records-table">
      @if(students().length === 0){
      <caption class="records-table__caption">
        Sin resultados
      </caption>
      }
      <thead>
        <tr>
          <th class="records-table__th">NOMBRE</th>
          <th class="records-table__th">DOCUMENTO</th>
          <th class="records-table__th">NOTA PERIODO</th>
          <th class="records-table__th">INASISTENCIAS</th>
          <th class="records-table__th">RECUPERACIÓN</th>
        </tr>
      </thead>
      <tbody>
        @for (item of students(); track $index) {
        <tr>
          <td class="records-table__td">
            {{ item.Student?.Name1 }} {{ item.Student?.Name2 ?? "" }}
            {{ item.Student?.LastName1 }} {{ item.Student?.LastName2 ?? "" }}
          </td>
          <td class="records-table__td">
            {{ item.Student?.UserDocument?.Document ?? "n/a" }}
          </td>
          <td class="records-table__td">
            <ng-container
              [ngTemplateOutlet]="editableInput"
              [ngTemplateOutletContext]="{
                canEdit: canEditRecord(),
                student: item,
                property: 'parcialRecord'
              }"
            />
          </td>
          <td class="records-table__td">
            <ng-container
              [ngTemplateOutlet]="editableInput"
              [ngTemplateOutletContext]="{
                canEdit: true,
                student: item,
                property: 'absences'
              }"
            />
          </td>
          <td class="records-table__td">
            <ng-container
              [ngTemplateOutlet]="editableInput"
              [ngTemplateOutletContext]="{
                canEdit: canEditRemedialGrade(),
                student: item,
                property: 'remedialGrade'
              }"
            />
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>

<ng-template
  let-student="student"
  let-property="property"
  let-canEdit="canEdit"
  #editableInput
>
  <input
    type="number"
    [readOnly]="!canEdit || !showEditInputs()"
    (change)="onInputChange(student, property, $event)"
    [value]="
      student.UserRecordsV2
        | recordPipe : this.selectedMinTerm()?.id! : property
    "
    class="records-table__input"
    [ngClass]="{ 'records-table__input--active': canEdit && showEditInputs() }"
  />
</ng-template>
