import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  computed,
  forwardRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-input',
  templateUrl: './ui-input.component.html',
  styleUrl: './ui-input.component.css',
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputComponent),
      multi: true,
    },
  ],
})
export class UiInputComponent implements ControlValueAccessor, OnInit {
  public ref = inject(ElementRef);

  type = input<'email' | 'text' | 'number'>('text');
  label = input<string>('');
  value = input<string | number>();
  placeholder = input<string>();
  invalid = input<boolean>();
  disabled = input<boolean>();
  variant = input<'flat' | 'bordered' | 'underline'>('flat');
  public clickedInside = signal<boolean>(false);
  public finalValue = signal<any>(null);
  private _activeInput = computed(() => this.clickedInside() || this.placeholder() || this.finalValue());
  public combinedClasses = computed(() => {
    const classes = {
      'input-field--active': this._activeInput() || this.ref.nativeElement.querySelector('.decorator')?.childNodes.length > 0,
      'disabled-input': this.disabled() || this.isDisabled(),
      'hidden-label': !this.label(),
      [this.variant()]:true,
      [`invalid-field invalid-field--${this.variant()}`]: this.invalid(),
      [`disabled disabled--${this.variant()}`]: this.disabled() || this.isDisabled()
    };
    return classes;
  });

  //#region ACCESSORS
  private _onChange: (value: any) => void = () => {};
  public onTouch = () => {};
  public isDisabled = signal<boolean>(false);
  //#endregion


  ngOnInit(): void {
    if(this.value())
      this.finalValue.set(this.value());
  }

  public onValueChange(event: Event) {
    let value = (event.target as HTMLInputElement).value.toString();
    this._onChange(value);
  }

  //#region ACCESSORS
  writeValue(value: string | number): void {
    this.finalValue.set(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if(this.disabled() || this.isDisabled()) 
      return;

    const target = event.target as HTMLElement;
    if (!this.ref.nativeElement.contains(target))
      this.clickedInside.set(false);
  }
}