import { Pipe, PipeTransform } from '@angular/core';
import { UserRecordV2 } from '@models/dbv2/user-record-v2';

@Pipe({
  name: 'recordPipe',
  standalone: true,
  pure: true,
})
export class RecordPipe implements PipeTransform {
  transform(
    records: UserRecordV2[],
    minTermId: number,
    property: string
  ): unknown {
    if (records.length === 0) return 0;

    const currentRecord = records.find((r) => r.midTermV2ID == minTermId);
    return currentRecord?.[property as keyof UserRecordV2] ?? 0;
  }
}
