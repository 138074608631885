<div class="performance">
  <div class="performance__header">
    <div class="performance__header-filters">
      <ui-select label="Selecctionar Materia" (onValueChange)="onSelectGroup($event.value)">
        <ui-select-content>
          @for (item of subjectGroups(); track $index) {
            <ui-select-item [value]="item.id!">{{item.SubjectV2Offer?.nameSubject}}</ui-select-item>
          }@empty {
            Sin resultados.
          }
        </ui-select-content>
      </ui-select>
  
      <ui-select label="Seleccionar Periodo" (onValueChange)="onSelectMinTerm($event.value)" >
        <ui-select-content>
          @for (item of minTerms(); track $index) {
            <ui-select-item [value]="item.id!">{{item.nameMid}}</ui-select-item>
          }@empty {
            Sin resultados.
          }
        </ui-select-content>
      </ui-select>
    </div>

    <div class="performance__header-actions">
      <button class="button button--bordered" (click)="onEditAll()">{{!updateAll() ? 'Actualizar' : 'Cancelar'}}</button>
      @if(updateAll()){
        <button class="button button--solid" (click)="saveAllTasks()">Guardar Todas</button>
      }
    </div>
  </div>

  <div class="performance__content">
    <table class="performance__table">
      @if( studentsData().length === 0 || performaces().length === 0){
        <caption class="performance__table-caption">
          Sin resultados
        </caption>
      }
      <thead>
        <tr>
          <th colspan="1" style="width: 200px;"></th>
          @for (item of performaces(); track $index) {
            <th class="performance__table-span" [attr.colspan]="item.tasksNumber">
              <div class="table-span__content">
                <div>
                  <div>{{item.performanceName}}</div>
                  <div>{{item.percentage}}%</div>
                </div>
                <div [title]="item.descripionPerformance">
                  <em class="icon-i-program-description-directive"></em>
                </div>
              </div>
            </th>
          }
          <th colspan="2" style="width: 100px;"></th>
        </tr>
        <tr>
          <th class="performance__table-th">Nombre del Estudiante</th>
          @for (performace of performaces(); track $index) {
            @for (task of [].constructor(performace.tasksNumber); track $index) {
              <th class="performance__table-th">{{$index + 1}}</th>
            }
          }
          <th class="performance__table-th" [ngStyle]="{'width.px': '110'}">Definitiva</th>
          <th class="performance__table-th"[ngStyle]="{'width.px': '70'}"></th>
        </tr>
      </thead>
      <tbody>
        @for (item of studentsData(); track item.id; let stdIdx = $index) {
          <tr>
            <td class="performance__table-td">{{item.UserSubjectV2?.Student?.Name1}}</td>
            @for (pfm of performaces(); track pfm; let pfmIdx = $index) {
              @for (tsk of [].constructor(pfm.tasksNumber); track tsk; let tskIdx = $index) {
                <td class="performance__table-td">
                  <input
                    type="number"
                    [readOnly]="!updateAll() && !item.canEdit"
                    (change)="onChangeRecord(item, pfm.id, tskIdx + 1, $event)"
                    [value]="item.tasks | tasksPipe :tskIdx + 1 :pfm.id"
                    class="performance__table-input"
                    [ngClass]="{ 'performance__table-input--active': updateAll() || item.canEdit }"
                  />
                </td>
              }
            }
            <td class="performance__table-td">{{item.parcialRecord}}</td>
            <td class="performance__table-td">
              @if(updateAll()){
                <button class="button performance__table-actions" (click)="saveTask(item)">
                  <em class="icon-i-pen"></em>
                </button>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>