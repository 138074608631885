import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, computed, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CourseOffer } from '@models/db/courseOffer';
import { ApiService } from '@services/api-service.service';
import { concat, map, switchMap, toArray } from 'rxjs';
import * as UiSelect from 'src/app/components/ui/ui-select';
import { RecordPipe } from './pipes/record.pipe';
import { SubjectV2GroupOffer } from '@models/dbv2/subject-v2-group-offer';
import { UserSubjectV2 } from '@models/dbv2/user-subject-v2';
import { MidTermsV2 } from '@models/dbv2/mid-terms-v2';
import { UserRecordV2 } from '@models/dbv2/user-record-v2';
import { XLSXService } from '@services/xlsx.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-records-management',
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    CommonModule,
    RecordPipe,
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,
  ],
  templateUrl: './records-management.component.html',
  styleUrl: './records-management.component.css',
})
export class RecordsManagementComponent implements OnInit {
  constructor(
    private _xlsxService: XLSXService,
    private _httpService: ApiService
  ) {}

  public slectedTab = signal<string>('');
  public courseOffers = signal<CourseOffer[]>([]);
  public subjectGroups = signal<SubjectV2GroupOffer[]>([]);
  public selectedSubjectGroup = signal<SubjectV2GroupOffer | null>(null);
  public selectedMinTerm = signal<MidTermsV2 | null>(null);
  public students = signal<UserSubjectV2[]>([]); // (userSubjects)
  public showEditInputs = signal<boolean>(false);

  public canEditRecord = computed(() => {
    if (!this.selectedMinTerm()) return false;

    const { midTermRecordStart, midTermRecordEnd } = this.selectedMinTerm()!;
    return this.isBetween(
      new Date(midTermRecordStart),
      new Date(midTermRecordEnd)
    );
  });

  public canEditRemedialGrade = computed(() => {
    if (!this.selectedMinTerm()) return false;

    const { remedialGradeStart, remedialGradeEnd } = this.selectedMinTerm()!;
    return this.isBetween(
      new Date(remedialGradeStart),
      new Date(remedialGradeEnd)
    );
  });

  private _tempSubecjtGroups: SubjectV2GroupOffer[] = [];

  ngOnInit(): void {
    Swal.fire({
      title: 'Cargando',
      html: 'Por favor, espera.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    const currentUserId = this._httpService.userInfo()!.id;

    //Get groups where I'm a teacher
    this._httpService
      .get<SubjectV2GroupOffer>({
        path: 'SubjectV2GroupOffers',
        filter: {
          include: ['SubjectV2Offer', 'MidTermsV2'],
          where: { UserID: currentUserId },
        },
      })
      .pipe(
        switchMap((groups) => {
          const offerIds = groups.map((g) => g.courseOfferID);
          const uniqueOfferIds = [...new Set(offerIds)];

          //Get the active courseOffers from all SubjectGroups
          return this._httpService
            .get<CourseOffer>({
              path: 'CoursesOfers',
              filter: {
                where: {
                  and: [{ id: { inq: uniqueOfferIds } }, { IsActive: true }],
                },
              },
            })
            .pipe(map((co) => ({ groups, courseOffers: co })));
        })
      )
      .subscribe({
        next: ({ courseOffers, groups }) => {
          this._tempSubecjtGroups = groups;
          this.courseOffers.set(courseOffers);
          Swal.close();
        },
        error: (err) => {
          Swal.fire({
            title: 'Error',
            text: 'Parece que algo salió mal. Si el problema persiste, por favor contacta a soporte técnico.',
            icon: 'error',
          });
          console.error(err);
        },
      });
  }

  //Filter the subjectGroups where the courseOffer is the same as the offerId
  public onSelectOffer(offerId: string) {
    const activeSubjectGroups = this._tempSubecjtGroups.filter(
      (t) => t.courseOfferID === Number(offerId)
    );
    this.subjectGroups.set(activeSubjectGroups);
  }

  public onSelectSubject(subjectGroupId: string) {
    Swal.fire({
      title: 'Cargando estudiantes...',
      html: 'Por favor, espera. Recuperando los estudiantes.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    const selection = this._tempSubecjtGroups.find(
      (sg) => sg.id === Number(subjectGroupId)
    );

    this.selectedSubjectGroup.set(selection!);
    const midTerms = this.selectedSubjectGroup()!.MidTermsV2;

    //This field stores the selected Period (MinTerm). By the default the first item in the list will be selected.
    this.selectedMinTerm.set(midTerms![0]);

    this.getStudentsQuery(this.selectedSubjectGroup()!.id!).subscribe({
      next: (resp) => {
        this.students.set(resp);
        Swal.close();
      },
      error: (err) => {
        Swal.fire({
          title: 'Error',
          text: 'Parece que algo salió mal. Si el problema persiste, por favor contacta a soporte técnico.',
          icon: 'error',
        });
        console.error(err);
      },
    });
  }

  public onInputChange(
    studentSubject: UserSubjectV2,
    property: string,
    event: Event
  ) {
    const value = (event.target as HTMLInputElement).value;

    const records = studentSubject.UserRecordsV2;

    const existingRecord: any = records.find(
      (record) => record.midTermV2ID === this.selectedMinTerm()!.id
    );

    if (existingRecord) {
      // If the userRecord already exists, update the property
      existingRecord[property] = value;
    } else {
      // If it doesn't exist, create one
      const newRecord = {
        parcialRecord: 0,
        absences: 0,
        midTermV2ID: this.selectedMinTerm()!.id,
        userSubjectV2ID: studentSubject.id,
        [property]: value,
      };

      records.push(newRecord);
    }

    this.students.update((prev) => {
      const subject = prev.find((s) => s.id === studentSubject.id)!;
      subject.UserRecordsV2 = records;
      return prev;
    });
  }

  public saveRecords() {
    Swal.fire({
      title: 'Guardando datos...',
      html: 'Por favor, espera mientras se actualizan los datos.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    const allRecords = this.students().flatMap((s) => s.UserRecordsV2);
    const records$ = allRecords.map((r) => {
      const path = r.id ? `UserRecordsV2/${r.id}` : 'UserRecordsV2';
      return this._httpService.patch<Partial<UserRecordV2>>({
        path,
        data: r,
      });
    });

    concat(...records$)
      .pipe(
        toArray(),
        switchMap(() => this.getStudentsQuery(this.selectedSubjectGroup()!.id!))
      )
      .subscribe({
        next: (resp) => {
          this.showEditInputs.set(false);
          this.students.set(resp);
          Swal.fire({
            title: '¡Datos actualizados!',
            text: 'Los datos se han guardado exitosamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        },
        error: (err) => {
          console.error(err);
          Swal.fire({
            title: 'Error',
            text: 'Parece que algo salió mal. Si el problema persiste, por favor contacta a soporte técnico.',
            icon: 'error',
          });
        },
      });
  }

  public exportFile() {
    if (this.students().length > 0) {
      const studentData = this.students();
      const midTerm = this.selectedMinTerm()!;

      const mappedData = studentData.map((s) => {
        const name = [
          s.Student?.Name1,
          s.Student?.Name2,
          s.Student?.LastName1,
          s.Student?.LastName2,
        ]
          .filter(Boolean)
          .join(' ');

        const currentRecord = s.UserRecordsV2.find(
          (r) => r.midTermV2ID === midTerm.id
        );

        const data = {
          Nombre: name,
          Documento: s.Student?.UserDocument!.Document,
          Periodo: `${midTerm.nameMid}(${midTerm.midTermNum})`,
          Percentage: `${midTerm.percentage}%`,
          NotaPeriodo: currentRecord ? currentRecord.parcialRecord ?? 0 : 0,
          Inasistencias: currentRecord ? currentRecord.absences ?? 0 : 0,
          Recuperación: currentRecord ? currentRecord.remedialGrade ?? 0 : 0,
        };

        return data;
      });

      this._xlsxService.exportFile(
        mappedData,
        `notas_estudiantes_${midTerm.nameMid}(${midTerm.midTermNum})`
      );
    }
  }

  public massiveUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0]!;

    const students = this.students();
    const midTerm = this.selectedMinTerm()!;

    Swal.fire({
      title: `<strong>Subir Notas</strong>`,
      html: `
        <p>Estas a punto de subir notas para <strong>${midTerm.nameMid} (${midTerm.midTermNum})</strong></p>
        <p>¿Deseas continuar?</p>
      `,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Sí, subir',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((resp) => {
      if (resp.isConfirmed) {
        this._xlsxService
          .loadFile(file)
          .then((resp) => {
            const data = resp[0].rows;
            const recordsToAdd: Partial<UserRecordV2>[] = [];

            Swal.fire({
              title: 'Subiendo notas...',
              html: `Subiendo las notas de <strong>${data[0].Nombre}</strong>`,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => Swal.showLoading(),
            });

            const studentMap = new Map(
              students.map((s) => [s.Student?.UserDocument?.Document, s])
            );

            for (let i = 0; i < data.length; i++) {
              const {
                Nombre,
                Documento,
                NotaPeriodo,
                Inasistencias,
                Recuperación,
              } = data[i];
              const currentStudent = studentMap.get(Documento.toString());
              if (currentStudent) {
                const userRecord = currentStudent.UserRecordsV2.find(
                  (r) => r.midTermV2ID === midTerm.id
                );

                //This is in case a student (userSubject) has already added
                if ( recordsToAdd.some((e) => e.userSubjectV2ID === userRecord?.id) )
                  continue;

                const absences = Inasistencias;

                /* If canEditRecord is true, it loads the value from data[i], otherwise, it applies the parcialRecord
                from userRecord if it exists, otherwise, it sets the value to 0  */
                const parcialRecord = this.canEditRecord()
                  ? NotaPeriodo
                  : userRecord?.parcialRecord ?? 0;

                //The same as above
                const remedialGrade = this.canEditRemedialGrade()
                  ? Recuperación
                  : userRecord?.remedialGrade ?? 0;

                let newRecord = {
                  midTermV2ID: midTerm.id,
                  absences,
                  parcialRecord,
                  remedialGrade,
                  userSubjectV2ID: currentStudent.id,
                };

                // Combine the existing data
                const finalRecord = userRecord ? { ...userRecord, ...newRecord } : newRecord;
                recordsToAdd.push(finalRecord);

                Swal.update({
                  text: `Subiendo las notas de <strong>${Nombre}</strong>`,
                });
              }
            }

            if (recordsToAdd.length === 0) {
              Swal.fire({
                title: 'Sin Cambios',
                text: 'No se encontraron notas nuevas para subir.',
                icon: 'info',
                confirmButtonText: 'Aceptar',
              });
              return;
            }

            const recordsToAdd$ = recordsToAdd.map((r) => {
              const path = r.id ? `UserRecordsV2/${r.id}` : 'UserRecordsV2';
              return this._httpService.patch<Partial<UserRecordV2>>({
                path,
                data: r,
              });
            });

            concat(...recordsToAdd$)
              .pipe(
                toArray(),
                switchMap(() =>
                  this.getStudentsQuery(this.selectedSubjectGroup()!.id!)
                )
              )
              .subscribe({
                next: (resp) => {
                  this.students.set(resp);
                  Swal.fire({
                    title: '¡Hecho!',
                    text: 'Las notas se subieron correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                  });
                },
                error: (err) => {
                  Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un problema al subir las notas. Por favor, inténtalo de nuevo.',
                    icon: 'error',
                  });
                  console.log(err);
                },
              });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }

  public exportTemplate() {
    this._xlsxService.exportFile(
      [
        {
          Nombre: '',
          Documento: '',
          NotaPeriodo: '',
          Inasistencias: '',
          Recuperación: '',
        },
      ],
      'plantilla_notas_estudiantes'
    );
  }

  public getStudentsQuery(subjectGroupId: number) {
    return this._httpService.get<UserSubjectV2>({
      path: 'UserSubjectsV2',
      filter: {
        include: [{ Student: 'UserDocument' }, { UserRecordsV2: 'MidTermV2' }],
        where: {
          and: [{ subjectV2GroupOfferID: subjectGroupId }],
        },
      },
    });
  }

  public isBetween(start: Date, end: Date) {
    const currentDate = new Date();
    return currentDate >= start && currentDate <= end;
  }
}
