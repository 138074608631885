import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@models/dbv2/task';

@Pipe({
  name: 'tasksPipe',
  standalone: true,
  pure: true,
})
export class TasksPipe implements PipeTransform {
  transform(
    tasks: Task[],
    taskNumer:number,
    performanceId:number,
  ): unknown {
    if (tasks.length === 0) return 0;

    const task = tasks.find( t => t.academicPerformanceOfferID === performanceId && t.taskNumber === taskNumer);
    return task?.taskRecord ?? 0
  }
}
